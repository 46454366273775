import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = ["id"]
const _hoisted_5 = {
  key: 1,
  class: "ml-3"
}
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!

  return (_openBlock(), _createBlock(_component_BaseInput, { "class-input": "border-transparent" }, {
    default: _withCtx(() => [
      _createElementVNode("label", {
        for: _ctx.uuid,
        class: _normalizeClass(["flex cursor-pointer items-center", {
      'inline-flex gap-2 p-2 border-2 w-min rounded-md ':_ctx.advanced
    }])
      }, [
        (_ctx.leftLabel && _ctx.advanced)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.leftLabel), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", _mergeProps(_ctx.$attrs, {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
            type: "checkbox",
            id: _ctx.uuid,
            class: "sr-only"
          }), null, 16, _hoisted_4), [
            [_vModelCheckbox, _ctx.model]
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["block h-6 w-11 rounded-full transition-all", _ctx.model ? 'bg-primary' : 'bg-dark']),
            style: _normalizeStyle(_ctx.forceColor ? 'background-color: ' + _ctx.forceColor : '')
          }, null, 6),
          _createElementVNode("div", {
            class: _normalizeClass(["absolute left-1.5 top-1 h-4 w-4 rounded-full bg-element transition-all dark:bg-light", { 'translate-x-full': _ctx.model }])
          }, null, 2)
        ]),
        (_ctx.label && !_ctx.advanced)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.label), 1))
          : _createCommentVNode("", true),
        (_ctx.label && _ctx.advanced)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.label), 1))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1)
    ]),
    _: 1
  }))
}