
import { concatClassCSS } from "@/utils";
import { computed, defineComponent } from "vue";
import BLoader from "./BLoader.vue";

export default defineComponent({
  name: "b-page",
  props: {
    contentScroll: {
      type: Boolean,
      default: () => true,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    loadingOver: {
      type: Boolean,
      default: () => false,
    },
    loadingMessage: {
      default: () => "",
    },
    forceNotSmall: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { slots }) {
     const pageCss = computed<string>(() => {
      let css = "flex flex-col overflow-auto h-full bg-content ";

      css = concatClassCSS(
        css,
        hasFooterSlot.value || hasHeaderSlot.value ? "sm:overflow-hidden" : ""
      );

      return css;
    });

    const hasHeaderSlot = computed<boolean>(() => {
      return !!slots.header;
    });

    const hasFooterSlot = computed<boolean>(() => {
      return !!slots.footer;
    });

    return {
      pageCss,
      hasHeaderSlot,
      hasFooterSlot,
    };
  },
  components: {
    BLoader,
  },
});
