import { IIntervenantContact } from "@/@models/intervenants";
import axios from "@/plugins/axios";
import { UserAccess, UserType } from "@/stores/auth";

const BASE_ENDPOINT = "VIEWER-IFC";

/**
 * Represente la réponse de l'API pour le login
 */
export interface ILoginResponse {
  token: string;
  user?: {
    index: number;
    prenom: string;
    nom: string;
  };
  utilisateur?: IIntervenantContact;
  multipleContacts?: boolean;
}

export const authService = {
  /**
   * Authentifie l'utilisateur et recupere un token
   * @param login
   * @param password
   * @param societe
   * @param idMachine
   * @param application
   * @param surnom
   * @returns
   */
  loginUser(payload: {
    login: string;
    mdp: string;
    societe: number;
    idmachine: string;
  }) {
    return axios.post<ILoginResponse>(`${BASE_ENDPOINT}/AUTHENTIFICATION`, {
      ...payload,
      application: "viewer IFC",
      surnom: "",
      type: UserType.user,
    });
  },

  loginIntervenant(payload: { login: string; mdp: string; societe: number }) {
    return axios.post<ILoginResponse>(`${BASE_ENDPOINT}/AUTHENTIFICATION`, {
      ...payload,
      application: "viewer IFC",
      surnom: "",
      type: UserType.intervenant,
    });
  },

  verifAuthentificationUser(payload: {
    login: string;
    societe: number;
    idmachine: string;
    token: string;
  }) {
    return axios.post<any>(`${BASE_ENDPOINT}/VERIF_AUTHENTIFICATION`, {
      ...payload,
      application: "viewer IFC",
      type: UserType.user,
    });
  },
  verifAuthentificationIntervenant(payload: {
    email: string;
    token: string;
    societe: number;
  }) {
    return axios.post<any>(`${BASE_ENDPOINT}/VERIF_AUTHENTIFICATION`, {
      ...payload,
      application: "viewer IFC",
      type: UserType.intervenant,
    });
  },

  changePasswordIntervenant(payload: {
    index: number;
    password: string;
    newPassword: string;
  }) {
    return axios.post<{
      result: boolean;
    }>(`ENTREPRISE/CHANGE_PASSWORD`, {
      ...payload,
      type: UserType.intervenant,
    });
  },

  resetPasswordIntervenant(payload: { societe: number; email: string }) {
    return axios.post<any>(`ENTREPRISE/RESET_PASSWORD`, { ...payload, type: UserType.intervenant });
  },

  getAccess() {
    return axios.get<{ acces: UserAccess[] }>(`ENTREPRISE/INTERVENANT/ACCES`);
},
};
