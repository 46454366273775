<template>
    <Datepicker
      v-bind="mergedProps"
      v-on="$attrs"  input-class="rounded w-1/2 " 
      />
  </template>
  
  <script>
  import { defineComponent, computed } from 'vue';
  import Datepicker from "@vuepic/vue-datepicker";
  
  export default defineComponent({
    name: 'CustomDatepicker',
    components: {
      Datepicker,
    },
    props: Datepicker.props,
    setup(props, { attrs }) {
      const mergedProps = computed(() => ({
        ...props, 
        locale: 'fr', 
        selectText: 'Appliquer',
        cancelText: 'Retour',
        ...attrs,
      }));
  
      return {
        mergedProps,
      };
    },
  });
  </script>
<style scoped> 

::v-deep input.dp__pointer {
  padding: 0px;
  padding-inline-start: var(--dp-input-icon-padding);
  padding-top: 4px;
  padding-bottom: 4px;
  
}

::v-deep .dp__main { 
  display: flex;
  flex-direction: column;
}


::v-deep .dp__action_row {
  display: flex;
  flex-direction: column; 
  gap: 6px;

}
::v-deep .dp__action_cancel {
  display: none;

}

::v-deep .dp__action_select {
  
  font-size: var(--font-size-md); 
  background-color: rgb(var(--color-primary));
  color: #ffffff;
}

::v-deep .dp__overlay_cell_active {
   
  background-color: rgb(var(--color-primary));
  color: #ffffff;
}
::v-deep .dp__calendar_header_item {
   
   font-weight: normal;
   font-size: var(--font-size-md) !important; 
   
 }

::v-deep .dp__selection_preview {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-lg) !important; 
  font-weight: bold;
  gap: 6px;

}
::v-deep .dp__cell_offset {
  color: #ffffff;
}
::v-deep .dp__pointer {

  --dp-highlight-color: white;
  --dp-primary-color:  rgb(var(--color-primary));
  --dp-primary-text-color: #ffffff;
  --dp-border-color: rgba(0, 0, 0, 0 ) !important;
  border: none !important;

  border: none !important;
  font-size: var(--font-size-md); 
}
::v-deep .dp__input_wrap:hover .dp__pointer,
::v-deep .dp__input_wrap:hover .dp__icon {
  color: rgb(var(--color-primary));
}
</style>