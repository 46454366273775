import { ILocalisationNavigation } from "@/pages/bureau/@types/localisation";
import localisationEvents from "./events.fr";

const localisationNav: ILocalisationNavigation = {
  home: "Accueil",

  pointages: "Pointages",

  tasks: "Tâches",

  rdv: "Rendez vous",

  expenses: "Notes de frais",
  expenseReport: "Frais",
  expenseList: "Mes dépenses",
  expenseReportList: "Mes notes de frais",
  expenseNew: "Nouvelle dépense",
  expenseReporteNew: "Nouvelle note",
  expenseStatistiques: "Statistiques",

  projects: "Projets",

  intervenants: "Intervenants",

  dataBase: "Base de connaissance",

  profil: "Mon profil",

  fil: "Fil de discussion",

  event: localisationEvents.title,
};

export default localisationNav;
