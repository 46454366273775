import { IDceZipFormat } from "@/@models/fichier";
import { IProjetTravauxType } from "@/@models/projets";
import {
  ISociete,
  ISocietePrefs,
  ISocieteTag,
  ISocieteUnite,
} from "@/@models/societes";
import axios from "@/plugins/axios";

export const downloadService = {
  
  downloadImageInter(index, predicate?): Promise<Blob> {
    return axios.get(`DOWNLOADS/IMAGE_INTER/${index}`, {
      responseType: "blob",
      onDownloadProgress: predicate,
    });
  },
 
  downloadDocTable(index, predicate?): Promise<Blob> {
    return axios.get(`DOWNLOADS/DOC-TABLE/${index}`, {
      responseType: "blob",
      onDownloadProgress: predicate,
    });
  },
 

  downloadPdfsTravaux(
    {
      documents,
      type,
    }: { documents: { index: number; name: string }[]; type: IProjetTravauxType },
    predicate?
  ): Promise<Blob> {
    return axios.post(
      `ENTREPRISE/TRAVAUX/DOWNLOAD_DOCUMENTS`,
      {
        documents,
        type,
      },
      {
        responseType: "blob",
        onDownloadProgress: predicate,
      }
    );
  },
};
