export  function getUrlOfBlob(blob: any): string {
  try {
    if (blob) {
        const url =  window.URL.createObjectURL(blob);
        return url;
      }
  } catch (error) {
     //
  }
  return '';
}