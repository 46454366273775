/**
 * @param data  ()=> data
 * @returns quand les données sont prêtes || true
 * @description on attends les données avec while et await
 */
export async function awaitData(getData: () => any , timeout = 100) {
  while (!(await getData())) {
    await new Promise((resolve) => setTimeout(resolve, timeout));
  }
  return await getData();
}
