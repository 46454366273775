
import BaseInput from "./BaseInput.vue";
import { computed, defineComponent, WritableComputedRef } from "vue";
import { generateUUID } from "@/utils";

export default defineComponent({
  name: "b-input-switch",
  components: { BaseInput },
  props: {
    modelValue: [Boolean, String],
    label: String,
    forceColor: String,
    advanced: Boolean,
    leftLabel: String,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const uuid = generateUUID();

    const model: WritableComputedRef<boolean> = computed({
      get(): boolean {
        return typeof props.modelValue == "boolean"
          ? (props.modelValue as boolean)
          : props.modelValue == "true";
      },
      set(newValue: boolean): void {
        emit("update:modelValue", newValue);
      },
    });

    return {
      uuid,
      model,
    };
  },
});
