import axios from "axios";
import axiosInstance from "@/plugins/axios";
import { generateToken } from "@/utils/tokens";
import { useBoxStore } from "@/stores";
import { IBoxItem, IBoxSharedLink } from "@/@models/box";

const BASE_ENDPOINT = "BOX";

export enum BOX_ENUM {
  DOWNLOAD = 1,
  UPLOAD = 2,
  LIST = 3,
  DELETE = 4,
  MOVE = 6,
  COPY = 10,
  TRASH = 11,
  SEARCH = 13,
  DELETE_PERMANENTLY = 14,
  VERSIONS = 15,
  PROMOTE_VERSION = 16,
  EVENTS = 17,
  SHARE = 18,
  ZIP_DOWNLOAD = 19,
  GET_EMAIL = 20,

  GET_PREVIEW_TEMP_LINK = 23,
  GET_SHARE_LINK = 24,
  ADD_SHARED_LINK = 25,
}

export const boxService = {
  getLoginUrl(clientId: string) {
    /* return (
      "https://www.box.com/api/oauth2/authorize?response_type=code&client_id=" +
      clientId +
      "&redirect_uri=" +
      "https://10.0.0.162:8081/ao&boxtoken" +
      "&state=boxtoken" +
      generateToken()
    );
    */
    return (
      "https://www.box.com/api/oauth2/authorize?response_type=code&client_id=" +
      clientId +
      "&redirect_uri=" +
      "https://localhost:4000/boxtoken" +
      "&state=boxtoken" +
      generateToken()
    );
  },
  getBoxSettings() {
    return axiosInstance.get<{
      clientID: string;
      clientSecret: string;
    }>(`${BASE_ENDPOINT}/SETTINGS`);
  },
  async getTokenWithCode(code: string, clientID: string, clientSecret: string) {
    return await axios.post("https://api.box.com/oauth2/token", {
      client_id: clientID,
      client_secret: clientSecret,
      code,
      grant_type: "authorization_code",
    });
  },

  async refreshToken(
    refreshToken: string,
    clientID: string,
    clientSecret: string
  ) {
    return axios.post(
      "https://api.box.com/oauth2/token",
      {
        client_id: clientID,
        client_secret: clientSecret,
        refresh_token: refreshToken,
        grant_type: "refresh_token",
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  },

  async getList(id: string, estDossier: boolean) {
    const token = await useBoxStore().getAccessToken();
    return axiosInstance.post(`${BASE_ENDPOINT}/URL/${BOX_ENUM.LIST}/EXEC`, {
      id,
      estDossier,
      token,
    });
  },
  getUserInfos(token: string) {
    return axiosInstance.post(
      `${BASE_ENDPOINT}/URL/${BOX_ENUM.GET_EMAIL}/EXEC`,
      {
        token,
      }
    );
  },

  async getContentFile(id: string) {
    const token = await useBoxStore().getAccessToken();
    return axios.get(`https://api.box.com/2.0/files/${id}/content`, { headers: { "authorization": `Bearer ${token}` }, responseType: 'blob' })
  },

  async previewFile(id: string) {
    const token = await useBoxStore().getAccessToken();
    /*curl -i -L -X GET "https://api.box.com/2.0/files/:id/content" \
     -H "authorization: Bearer <ACCESS_TOKEN>" \*/
    //          responseType: 'blob',
    //return axios.get(`https://api.box.com/2.0/files/${id}/content`, { headers: { "authorization": `Bearer ${token}` }, responseType: 'blob' });

    /*
curl https://api.box.com/2.0/files/12345?fields=expiring_embed_link \
    -H "authorization: Bearer ACCESS_TOKEN"
    */

    /*
    return axios.get(
      `https://api.box.com/2.0/files/${id}?fields=expiring_embed_link`,
      { headers: { authorization: `Bearer ${token}` } }
    );
    */

    return axiosInstance.post(
      `${BASE_ENDPOINT}/URL/${BOX_ENUM.GET_PREVIEW_TEMP_LINK}/EXEC`,
      {
        id,
        token,
      }
    );
  },

  async postShareLink(parameters:{
    id: string;
    supprimer: boolean;
    access: IBoxSharedLink["access"];
    can_download: boolean;
    estDossier: boolean;
  }) {
    const token = await useBoxStore().getAccessToken();

    return axiosInstance.post(
      `${BASE_ENDPOINT}/URL/${BOX_ENUM.SHARE}/EXEC`,
      {
        ...parameters,
        token,
      }
    );
  },

  async addShareLink(parameters:{
    id: string;
    estDossier: boolean;
  }) {
    const token = await useBoxStore().getAccessToken();

    return axiosInstance.post(
      `${BASE_ENDPOINT}/URL/${BOX_ENUM.ADD_SHARED_LINK}/EXEC`,
      {
        ...parameters,
        token,
      }
    );
  },
  async getProjetFiles(uid_projet:string) {
    const token = await useBoxStore().getAccessToken();
    const email = (await useBoxStore().getUserInfos())?.login;
    

    return axiosInstance.post(
      `${BASE_ENDPOINT}/URL/PROJET/${uid_projet}`,
      { 
        token,
        email
      }
    );
  },
};
