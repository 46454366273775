import { IconType } from "@/components/global";
import { awaitData, TEMPORARY_isDev } from "@/utils";
import { ref } from "vue";
import { useProjet } from "../composables";
import router from "@/pages/ao/router";

interface IMenuItem {
  to: string;
  icon: IconType;
  text: string;
  condition?: boolean;
}

export { IMenuItem };

export function getMenuEspaceEntreprise({
  canAccessProjets = false,
  canAccessConsultation = false,
  canAccessEspaceEntreprise = false,
  canAccessEvenements = false,
}: {
  canAccessProjets?: boolean;
  canAccessConsultation?: boolean;
  canAccessEspaceEntreprise?: boolean;
  canAccessEvenements?: boolean;
}) {
  const _items = [
    {
      to: "identite",
      icon: "identity",
      text: "Identité",
      condition: true,
    },
    {
      to: "contacts",
      icon: "contact",
      text: "Contacts",
      condition: true,
    },
    {
      to: "entreprise-documents",
      icon: "folder",
      text: "Documents",
      condition: true,
    },
    {
      to: "box",
      icon: "box",
      text: "Paramètres Box",
      condition: TEMPORARY_isDev(),
    },
    {
      to: "historique",
      icon: "hourglass",
      text: "Historique",
      condition: canAccessConsultation,
    },
  ];

  return _items.filter((item) => item.condition) as IMenuItem[];
}

export async function getMenuProjets() {
  const projetUid = await awaitData(
    () => router.currentRoute.value.params.uid_projet
  );

  const { fetchProjetInterBibAccess } = useProjet({
    uidProjet: projetUid as string,
  });

  const { estEntreprise, estMoa } = await fetchProjetInterBibAccess();

  const _items = [
    {
      to: "projet_info",
      icon: "information",
      text: "Informations",
      condition: true,
    },
    {
      to: "projet_intervenants",
      icon: "group",
      text: "Annuaire",
      condition: true,
    },
    {
      to: "projet_adjudication",
      icon: "offer",
      text: "Adjudication",
      condition: estMoa,
    },
    {
      to: "projet_travaux",
      icon: "compta",
      text: "Travaux",
      condition: estEntreprise || estMoa,
    },
    {
      to: "projet_box",
      icon: "box",
      text: "Box",
      condition: TEMPORARY_isDev(),
    },
  ];

  return _items.filter((item) => item.condition) as IMenuItem[];
}

export function getMenuConsultations() {
  const _items = [
    {
      to: "information",
      icon: "information",
      text: "Informations",
      condition: true,
    },
    {
      to: "dce",
      icon: "dce_bis",
      text: "DCE",
      condition: true,
    },
    {
      to: "questions",
      icon: "question",
      text: "Questions / réponses",
      condition: true,
    },
    {
      to: "lots",
      icon: "offer",
      text: "Offres financières",
      condition: true,
    },
    {
      to: "candidature",
      icon: "candidature",
      text: "Candidatures",
      condition: true,
    },
  ];

  return _items.filter((item) => item.condition) as IMenuItem[];
}
