import { IBoxItem, IBoxSharedLink } from "@/@models/box";
import { boxService } from "@/api/box";
import { awaitData, isValidStatus } from "@/utils";
import axios from "axios";
import { defineStore } from "pinia";

export const useBoxStore = defineStore("box", {
  state: () => {
    const access_token = localStorage.getItem("box_access_token");
    const refresh_token = localStorage.getItem("box_refresh_token");
    const access_token_timestamp = localStorage.getItem(
      "box_access_token_timestamp"
    );
    const clientID = "";
    const clientSecret = "";

    const box_email = ""

    return {
      access_token: access_token ?? "",
      refresh_token: refresh_token ?? "",
      access_token_timestamp: access_token_timestamp ?? 0,
      clientID,
      clientSecret,
      box_email
    };
  },
  actions: {
    async getLoginUrl() {
      await this.checkClient();
      return boxService.getLoginUrl(this.clientID);
    },
    async getAccessToken() {
      const copy_access_token = this.access_token;
      if (this.access_token_timestamp <= new Date().getTime()) {
        await this.refreshToken();
      }
      if(((copy_access_token != this.access_token ) || (!this.box_email)) ){
        const res = (await boxService.getUserInfos(this.access_token))
        if(res.status==200){
          this.box_email=res?.data?.box_data?.login
        }
      }
      return this.access_token;
    },
    async getBoxSettings() {
      const { status, data } = await boxService.getBoxSettings();
      if (!isValidStatus(status)) {
        console.error("Erreur lors de la récupération des paramètres de Box");
        this.clientID = "";
        this.clientSecret = "";
      }
      this.clientID = data?.clientID ?? "";
      this.clientSecret = data?.clientSecret ?? "";
      return data;
    },

    setAccessToken(token: string, timestamp = 0) {
      this.access_token = token;
      localStorage.setItem("box_access_token", token);
      this.access_token_timestamp = timestamp;
      localStorage.setItem("box_access_token_timestamp", timestamp + "");
    },
    setRefreshToken(token: string) {
      this.refresh_token = token;
      localStorage.setItem("box_refresh_token", token);
    },

    clearTokens() {
      this.setAccessToken("");
      this.setRefreshToken("");
    },

    async fetchTokenWithCode(code: string) {
      await this.checkClient();
      const res = await boxService.getTokenWithCode(
        code,
        this.clientID,
        this.clientSecret
      );
      if (res.status !== 200) {
        console.error("Erreur lors de la récupération du token");
        return;
      }
      this.setAccessToken(res.data?.access_token);
      this.setRefreshToken(res.data?.refresh_token);
    },
    async checkClient() {
      if (this.clientID === "" || this.clientSecret === "") {
        await this.getBoxSettings();
      }
      await awaitData(() => this.clientID !== "" && this.clientSecret !== "");
    },
    async refreshToken() {
      await awaitData(() => this.refresh_token !== "");
      await this.checkClient();
      const res = await boxService.refreshToken(
        this.refresh_token,
        this.clientID,
        this.clientSecret
      );

      if (res.status == 200) {
        const timestamp = new Date().getTime() + res.data?.expires_in * 1000;
        this.setAccessToken(res.data?.access_token, timestamp);
        this.setRefreshToken(res.data?.refresh_token);
      }

      return res.data;
    },
    async getListOfElements(id: string) {
      const res = await boxService.getList(id, true); 
      return (res?.data?.box_data?.entries ?? []) as IBoxItem[];
    },

    async getUserInfos() {
      const res = await boxService.getUserInfos(await this.getAccessToken());
      return res?.data?.box_data;
    },

    async downloadFile(id: string,fileName="file") {
      const res = await boxService.getContentFile(id);
      const urlFile= URL.createObjectURL(res?.data) 
      if (urlFile) {
        const link = document.createElement("a");
        link.href = urlFile;
        link.download = fileName?? "file";
        link.click();
      }
    },

    async previewFile(id: string) {
      const res = await boxService.previewFile(id);
      if (res.status !== 200) {
        console.error("Erreur lors de la récupération du fichier");
        return;
      } /*
      return {
        url: URL.createObjectURL(res.data),
        type: res?.headers?.["content-type"],
      };*/
      return {
        url: res?.data?.box_data?.expiring_embed_link?.url,
        type: "",
      };
    },
    async openFileOnBox(id: string) {
      const base_url = "https://app.box.com/file/";
      window.open(base_url + id, "_blank");
    },

    async editShareLink(parameters: { id: string; access: IBoxSharedLink["access"]; can_download: boolean,
      estDossier: boolean;
     }) {
      const res = await boxService.postShareLink({
        id: parameters.id,
        access: parameters.access,
        can_download: parameters.can_download,
        supprimer: false,
        estDossier: parameters.estDossier
      });
      if (res.status !== 200) {
        console.error("Erreur lors de la récupération du fichier");
        return "";
      } 
      return res?.data?.box_data?.shared_link;
    },
    async removeShareLink(parameters:{
      id: string;
      estDossier: boolean;
    }) {
      const res = await boxService.postShareLink({
        id : parameters.id,
        access: "open",
        can_download: false,
        supprimer: true,
        estDossier : parameters.estDossier
      });
      if (res.status !== 200) {
        console.error("Erreur lors de la récupération du fichier");
        return "";
      }
      return res?.data?.box_data?.shared_link;
    },

    async getProjetFiles(  uid_projet:string) {
      const res = await boxService.getProjetFiles(  uid_projet);
      if (res.status !== 200) {
        console.error("Erreur lors de la récupération des fichiers");
        return [] 
      } 
      return (res?.data?.box_data?.entries  ?? []) as  IBoxItem[] 
    }
  },
});
