import { computed, reactive } from "vue";
import accessTemp from "../store-temp/access.temp";
import { formatEur, TEMPORARY_isDev } from "@/utils";
import { useI18n } from "vue-i18n";
import { IconType } from "@/components/global";

 

export interface IUserTab {
  title: string;
  label: string;
  mainStat: string;
  logo: IconType;
  routeName: string;
  ordre: number;
  disabled: boolean;
}

export function useUserTabs(stats?: any) {
  const { t } = useI18n();

  const canReadProjet = computed(() => accessTemp.get("PROJETS-READ"));
  const canReadIntervenant = computed(() =>
    accessTemp.get("INTERVENANTS-READ")
  );
  const canReadPointage = computed(() => accessTemp.get("POINTAGES-READ"));
  const canReadFrais = computed(() => accessTemp.get("FRAIS-READ"));
  const canReadBase = computed(() => accessTemp.get("BASE-READ"));
  const canReadTaches = computed(() => accessTemp.get("TACHES-READ"));
  const canReadFil = computed(() => accessTemp.get("FIL-READ"));
  const canReadEvents = computed(() => TEMPORARY_isDev() && accessTemp.get("EVENTS-READ"));

  const perso_tabs = reactive<IUserTab[]>(
    [
      {
        title: t("global.home.expenses"),
        label: t("navigation.expenseReport"),
        mainStat: formatEur(stats?.fraisMontant),
        logo: "frais",
        routeName: "expenses",
        ordre: 1,
        disabled: !canReadFrais.value,
      },
      {
        title: t("global.home.pointages"),
        label: t("navigation.pointages"),
        mainStat: stats?.planningHeure,
        logo: "pointage",
        routeName: "pointages",
        ordre: 2,
        disabled: !canReadPointage.value,
      },
      {
        title: t("global.home.tasks"),
        label: t("navigation.tasks"),
        mainStat: stats?.tache,
        logo: "taches",
        routeName: "tasks",
        ordre: 3,
        disabled: !canReadTaches.value,
      },
      {
        title: t("global.home.fil"),
        label: t("navigation.fil"),
        mainStat: stats?.nbMessageNonLu,
        logo: "question",
        routeName: "fdc",
        ordre: 4,
        disabled: !canReadFil.value,
      },
      {
        title: t("global.home.event"),
        label: t("navigation.event"),
        mainStat: stats?.events ,
        logo: "events",
        routeName: "events",
        ordre: 5,
        disabled: !canReadEvents.value,
      },
      {
        title: t("global.home.rdv"),
        label: "",
        mainStat: stats?.rdv,
        logo: "event",
        routeName: "rdv",
        ordre: 10,
        disabled: true,
      },
    ].filter((tab) => !tab.disabled) as IUserTab[]
  );

  const data_tabs = reactive<IUserTab[]>(
    [
      {
        title: t("global.home.projet"),
        label: t("navigation.projects"),
        mainStat: stats?.projetsEnCours,
        logo: "consultation",
        routeName: "projects",
        ordre: 5,
        disabled: !canReadProjet.value,
      },
      {
        title: t("global.home.intervenant"),
        label: t("navigation.intervenants"),
        mainStat: stats?.intervenants,
        logo: "intervenants",
        routeName: "intervenants",
        ordre: 6,
        disabled: !canReadIntervenant.value,
      },
      {
        title: t("global.home.article"),
        label: t("navigation.dataBase"),
        mainStat: stats?.articles,
        logo: "base",
        routeName: "base-connaissance",
        ordre: 7,
        disabled: !canReadBase.value,
      },
    ].filter((tab) => !tab.disabled) as IUserTab[]
  );
  return {
    perso_tabs,
    data_tabs,
  };
}
