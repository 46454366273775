
import { defineStore } from 'pinia'
import { Idce_tri_values } from '../utils/dce'


const STORAGE_KEY = "bimoffice_ao_prefs"

interface IAoState {
    darkMode: boolean,
    fullscreen: boolean,
    /**
     * Ouvre/ferme le menu de navigation
     */
    drawer: boolean,
    /**
     * Affiche les consultations archivé
     */
    archive: boolean,
    collapseSidebar: boolean,
    lotRefuse: boolean,
    detailOffre: boolean,

    dce_tri:  Idce_tri_values,// tri des documents du DCE
    dce_list:boolean,// si on affiche en liste ou en grille
}


/**
 * Contient les prefs global pour la partie appel d'offre
 */
export const useAOStore = defineStore('ao', {
    state: (): IAoState => {
        let data: Partial<IAoState> = null
        const stored = localStorage.getItem(STORAGE_KEY)
        if (stored) {
            data = JSON.parse(stored)
        }
        // Si aucune prefs enregistrée, on récupere le média pour le darkmode
        else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            data = {
                darkMode: true
            }
        }

        return {
            darkMode: data?.darkMode ?? false,
            fullscreen: data?.fullscreen ?? false,
            drawer: data?.drawer ?? false,
            archive: data?.archive ?? false,
            collapseSidebar: data?.collapseSidebar ?? false,
            lotRefuse: data?.lotRefuse ?? false,
            detailOffre: data?.detailOffre ?? false,
            dce_tri: data?.dce_tri ?? "def",
            dce_list: data?.dce_list ?? true,
        }
    },
    actions: {
        fetchUser() {

        },
        toggleDrawer() {
            this.drawer = !this.drawer
        },
        toggleCollapseSidebar() {
            this.collapseSidebar = !this.collapseSidebar
        },

        saveStateInLocalStorage() {
            localStorage.setItem(STORAGE_KEY, JSON.stringify(this.$state))
        },
        saveNewStateInLocalStorage(state: Partial<IAoState>) { 
            const newState = { ...this.$state, ...state } 
            localStorage.setItem(STORAGE_KEY, JSON.stringify(newState))
            this.$state = newState
        }
    }
})