import global from "./global.fr"
import auth from "./auth.fr"
import error from "./error.fr"
import navigation from "./navigation.fr"
import expense from "./expense.fr"
import projects from "./projects.fr"
import intervenants from "./intervenants.fr"
import pointages from "./pointages.fr"
import taches from "./taches.fr";
import base from "./base.fr";
import access from "./access.fr";
import fil from "./fil.fr";
import events from "./events.fr";
import { ILocalisation } from "@/pages/bureau/@types/localisation";

const localisationFr: ILocalisation = {
  global,
  auth,
  error,
  navigation,
  expense,
  projects,
  intervenants,
  pointages,
  taches,
  base,
  access,
  fil,
  events
};

export default localisationFr;