import { IProjet } from "@/@models/projets";
import { projetsService } from "@/api";
import { checkData, isUUIDValid, isValidStatus } from "@/utils";
import { computed, isRef, ref, unref, watch } from "vue";
import { useToast } from "vue-toastification";

import type { ComputedRef, Ref, WatchStopHandle } from "vue";

interface UseProjetProps {
  uidProjet: string | Ref<string> | ComputedRef<string>;

  /**
   * Si vrai, charge immediatement la projet et recharge à chaque changement de 'uidProjet'
   * Il faut recreer l'instance si changer apres
   */
  autoFetch?: boolean;
}

export function useProjet({ uidProjet, autoFetch }: UseProjetProps) {
  const projet = ref<IProjet | null>(null);
  const logo = ref<Blob[]>([]);

  const loading = ref<boolean>(false);
  const isFetched = ref<boolean>(false);

  const fetchError = ref<Error>(null);

  const loadingLogo = ref<boolean>(false);
  const isFetchedLogo = ref<boolean>(false);

  const fetchErrorLogo = ref<Error>(null);

  let watchStop: WatchStopHandle = null;

  const projetCode = computed(() => projet.value?.code ?? "");
  const projetNom = computed(() => projet.value?.nom ?? "");

  async function fetch() {
    const toastification = useToast();

    fetchError.value = null;

    const _uidProjet = isRef(uidProjet) ? uidProjet.value : uidProjet;

    if (!isUUIDValid(_uidProjet)) {
      projet.value = null;
      isFetched.value = false;
      loading.value = false;
      console.error("Error: UUID Projet vide ou invalide !");
      return;
    }

    loading.value = true;

    try {
      const { status, data } = await projetsService.getProjet(_uidProjet);

      if (!isValidStatus(status) || !checkData(data)) {
        projet.value = null;
        toastification.error("La récupération du projet a échouée.");
      } else {
        projet.value = data.projet;
        isFetched.value = true;
      }
    } catch (err) {
      projet.value = null;
      fetchError.value = err;
    } finally {
      loading.value = false;
    }
  }

  async function fetchLogo(image_id: string) {
    fetchErrorLogo.value = null;
    loadingLogo.value = true;

    const _uidProjet = isRef(uidProjet) ? uidProjet.value : uidProjet;

    try {
      const res = await projetsService.getProjetLogo(_uidProjet, image_id);
      if (!(res instanceof Blob)) {
        //logo.value = null;
        //isFetchedLogo.value = false;
      } else {
        logo.value.push(res);
        isFetchedLogo.value = true;
      }
    } catch (err) {
      console.error(err);
      //logo.value = null;
      fetchError.value = err;
    } finally {
      loadingLogo.value = false;
    }
  }

  async function fetchProjetInterBibAccess() {
    const _uidProjet = isRef(uidProjet) ? uidProjet.value : uidProjet;
    let estEntreprise = false;
    let estMoa = false;
    try {
      const { status, data } = await projetsService.getProjetInterBibAccess(
        _uidProjet
      );
      if (isValidStatus(status)) {
        estEntreprise = data?.estEntreprise;
        estMoa = data?.estMoa;
      }
    } catch (err) {
      //
    }

    return {
      estEntreprise,
      estMoa,
    };
  }

  watch(
    () => autoFetch,
    (value) => {
      if (value) {
        if (typeof watchStop == "function") {
          watchStop();
        }
        watchStop = watch(
          isRef(uidProjet) ? uidProjet : () => uidProjet,
          fetch,
          {
            immediate: true,
          }
        );
      } else {
        if (typeof watchStop == "function") {
          watchStop();
        }
      }
    },
    {
      immediate: true,
    }
  );

  const reset = () => {
    projet.value = null;
    logo.value = [];
    isFetched.value = false;
    loading.value = false;
    fetchError.value = null;

    isFetchedLogo.value = false;
    loadingLogo.value = false;
    fetchErrorLogo.value = null;
  };

  return {
    projet,
    logo,

    projetCode,
    projetNom,

    loading,
    loadingLogo,

    isFetched,
    isFetchedLogo,

    fetchError,
    fetchErrorLogo,

    fetch,
    fetchLogo,
    fetchProjetInterBibAccess,

    reset,
  };
}
