export function firstUpperCase(str) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getFileName(str) {
  // un nom de fichier peux etre fac.txt ou fac.2.txt
  const tab = str.split(".");
  tab.pop();
  return tab.join(".");
}
export function getFileExtension(str) {
  return str.split(".").pop();
}

export function formatBRforVHtml(str) {
  const nStr = str || "";
  return nStr.replaceAll("\r", "<br>");
}

/**
 *
 * @param str1  string 1
 * @param str2  string 2
 * @param separator  separateur entre les deux chaines (default " ")
 * @returns string (str1 + separator + str2 ) en filtrant les chaines vides
 */
export function concatenerString(str1, str2, separator = " ") {
  const strArray = [str1, str2].filter((s) => s);
  return strArray.join(separator);
}

/**
 *
 * @ sers à remplacer les caractères spéciaux par des caractères normaux
 * @ les espaces underscores et tirets sont conservés
 * @ les accents sont remplacés par les lettres de base
 * @example "Documents Généraux" => "Documents Generaux"
 * @example "Doc Précis n°1" => "Doc Precis n_1"
 */
export function remplacerCaracteresSpeciaux(nom) {
  // Tronquer le nom si sa longueur est supérieure à 30 caractères
  let _nom = nom;

  // Fonction pour remplacer les accents par les lettres de base
  const remplacerAccents = (str) => {
    const accentsMap = {
      á: "a",
      à: "a",
      â: "a",
      ä: "a",
      ã: "a",
      å: "a",
      æ: "ae",
      ç: "c",
      é: "e",
      è: "e",
      ê: "e",
      ë: "e",
      í: "i",
      ì: "i",
      î: "i",
      ï: "i",
      ñ: "n",
      ó: "o",
      ò: "o",
      ô: "o",
      ö: "o",
      õ: "o",
      ø: "o",
      œ: "oe",
      ú: "u",
      ù: "u",
      û: "u",
      ü: "u",
      ý: "y",
      ÿ: "y",
      Á: "A",
      À: "A",
      Â: "A",
      Ä: "A",
      Ã: "A",
      Å: "A",
      Æ: "AE",
      Ç: "C",
      É: "E",
      È: "E",
      Ê: "E",
      Ë: "E",
      Í: "I",
      Ì: "I",
      Î: "I",
      Ï: "I",
      Ñ: "N",
      Ó: "O",
      Ò: "O",
      Ô: "O",
      Ö: "O",
      Õ: "O",
      Ø: "O",
      Œ: "OE",
      Ú: "U",
      Ù: "U",
      Û: "U",
      Ü: "U",
      Ý: "Y",
      Ÿ: "Y",
    };
    return str.replace(/[^A-Za-z0-9 _]/g, (char) => accentsMap[char] || char);
  };

  // Remplacer les accents dans le nomDossier
  _nom = remplacerAccents(_nom);

  // Conserver uniquement les chiffres, lettres, espaces , underscores et tirets
  _nom = _nom.replace(/[^A-Za-z0-9 _-]/g, "");

  return _nom;
}

export function cutStringEllipsis(str: string, length: number) {
  if (str.length <= length) return str;
  return str.substring(0, length) + "..";
}

export function formatNom(nom: string, sizeLimit = 40) {
  const nomDossier =
    nom.length > sizeLimit ? nom.substring(0, sizeLimit) + "-" : nom;

  return remplacerCaracteresSpeciaux(nomDossier);
}
