export const defaultTypeDates = [
  {
    label: "Jour",
    value: "day",
  },
  {
    label: "Semaine",
    value: "week",
  },
  {
    label: "Mois",
    value: "month",
  },
  {
    label: "Année",
    value: "year",
  },
  {
    label: "Personnalisé",
    value: "custom",
  },
  {
    label: "Tout",
    value: "all",
  },
] as const


export type INavigatorTypeDate = (typeof defaultTypeDates)[number]["value"]
