import { IPrefsLogin } from "@/pages/bureau/store-temp/prefs.temp";
import axios from "./index";

const BASE_ENDPOINT = "USER";

/**
 * Represente la réponse de l'API pour le login
 */
export interface ILoginResponse {
  token: string;
  user: {
    index: number;
    prenom: string;
    nom: string;
  };
}

export const authService = {
  /**
   * Authentifie l'utilisateur et recupere un token
   * @param login
   * @param password
   * @param societe
   * @param idMachine
   * @param application
   * @param surnom
   * @returns
   */
  login(
    login: string,
    password: string,
    societe: number,
    idMachine: string,
    application = "BIMoffice online",
    surnom = "bimoffice online"
  ) {
    return axios.post<ILoginResponse>(`${BASE_ENDPOINT}/AUTHENTIFICATION`, {
      login,
      mdp: password,
      societe,
      idmachine: idMachine,
      application,
      surnom,
    });
  },
  
  /**
   * Authentifie l'UG et recupere un token
   * @param login
   * @param password
   * @param societe
   * @param idMachine
   * @param application
   * @param surnom
   * @returns
   */
  loginAsUG(
    login: string,
    password: string,
    societe: number, 
  ) {
    return axios.post<ILoginResponse>(`${BASE_ENDPOINT}/AUTHENTIFICATION/UG`, {
      login,
      mdp: password,
      societe, 
    });
  },


  loginChangeUserUG(
    userIndex: number, 
  ) {
    return axios.get<ILoginResponse>(`${BASE_ENDPOINT}/AUTHENTIFICATION/UG/CHANGE_USER/${userIndex}`);
  },

  fetchUserImage(): Promise<Blob> {
    return axios.get(`USER/IMAGE`, {
      responseType: "blob",
    });
  },

  getLoginPrefs() {
    return axios.get<{
      prefs: IPrefsLogin;
    }>(`BUREAU/LOGIN/PREFS`);
  },
};
