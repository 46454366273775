import { ISociete, ISocieteUnite } from "@/@models/societes";
import { societesService } from "@/api/societes";
import router from "@/pages/ao/router";
import { rgbToHex } from "@/utils/colors";
import { darkenColor, hexToRgb, lightenColor, lightenIfTooDark } from "@/utils/colors";
import { isValidStatus } from "@/utils/http";
import { defineStore } from "pinia";

export const useSocietesStore = defineStore("societes", {
  state: () => {
    return {
      societes: [] as ISociete[],
      selected: -1,

      unites: [] as ISocieteUnite[],
      error: null,

      // Prefs values

      /**
       * Appel d'offre activé
       */
      active: false,

      /**
       * Nom du serveur
       */
      nom: "",

      /**
       * Autorise le stockage de document privé
       */
      stockage: false,

      /**
       * Limite du stockage de document privé, en Mo, (si 0, pas de limite)
       */
      limitStockage: 0,

      /**
       * Slogan de la société
       */
      slogan: "",

      /**
       * Image de présentation de la société
       */
      image: null,

      /**
       * Logo taille normal de la société
       */
      logo: null,

      /**
       * Logo taille normal de la société CLAIR
       */
      logoModeClair: null,
      /**
       * Logo taille normal de la société SOMBRE
       */
      logoModeSombre: null,

      /**
       * Couleur de fond pour l'image de présentation
       */
      color: "#FFFFFF",

      /**
       * Instruction de connexion, pour l'écran de Login
       */
      instruction: "",

      /**
       * Titre Instruction de connexion, pour l'écran de Login
       */
      titreInstruction: "",

      /**
       * Message si l'Appel d'offre est desactivé
       */
      desactive: "",

      /**
       * Si les entreprises sont autorisé à modifier les données personnelles
       */
      majInfoEntreprise: false,

      historique: false,
      limitMaxHisto: 0,

      primaryColor: "#FFFFFF",
      secondaryColor: "#FFFFFF",
      darkColor: "#FFFFFF",
    };
  },
  getters: {
    getUniteByAbreviation(state) {
      return (abv) =>
        abv && state.unites && state.unites.find((u) => u.abreviation == abv);
    },
    logoDefault(state) {
      if (state.logoModeClair || state.logoModeSombre) {
        if (state.logoModeClair) {
          return state.logoModeClair;
        } else {
          return state.logoModeSombre;
        }
      } else {
        if (state.logo) {
          return state.logo;
        }
      }
      return null;
    },
  },
  actions: {
    /**
     * Recuperes les societes
     * @returns
     */
    async fetchSocietes(): Promise<boolean> {
      const { status, data } = await societesService.getSocietes();
      if (!isValidStatus(status)) {
        this.societes = [];
        this.error = Error("La récupération des sociétés a échoué.");
        return false;
      }
      if (!data || !(data.societes && data.societes.length)) {
        this.societes = [];
        this.error = Error("La récupération des sociétés a échoué.");
        return false;
      }
      this.societes = data.societes;

      return true;
    },
    /**
     * Sélectionnne une societe et recupere ses prefs
     * @returns
     */
    async selectSociete(index: number): Promise<boolean> {
      if (router.currentRoute.value.name === "blank") return;
      this.clearPrefs();
      const { status, data } = await societesService.getSocietePrefs(index);
      if (!isValidStatus(status)) {
        this.selected = -1;
        this.error = Error("La récupération des sociétés a échoué.");
        return false;
      }
      this.selected = index;
      this.active = data?.prefs?.active == "1";
      this.nom = data?.prefs?.nom ?? "";
      this.stockage = data?.prefs?.stockage == "1";
      this.limitStockage = data?.prefs?.limitStockage ?? 0;
      this.slogan = data?.prefs?.slogan ?? "";
      this.image = data?.prefs?.bandeau ?? null;
      this.logo = data?.prefs?.grandLogo ?? null;
      this.logoModeClair = data?.prefs?.logoModeClair ?? null;
      this.logoModeSombre = data?.prefs?.logoModeSombre ?? null;
      this.titreInstruction = data?.prefs?.titreInstruction ?? "";
      this.color = data?.prefs?.couleur ?? "#FFFFFF";
      this.instruction = data?.prefs?.instruction ?? "";
      this.majInfoEntreprise = data?.prefs?.majInfoEntreprise == "1";
      this.historique = data?.prefs?.historique;
      this.limitMaxHisto = data?.prefs?.limitMaxHisto ?? 0;
 
/*
      this.primaryColor = data?.prefs?.primaryColor ?? "#008CFF"; 
      this.secondaryColor =  rgbToHex(lightenColor(hexToRgb(this.primaryColor),240));
      this.darkColor =  rgbToHex(darkenColor(hexToRgb(this.primaryColor),240));

      
      document.documentElement.style.setProperty(
        "--color-primary",
        hexToRgb(this.primaryColor).join(" ")
      );
      document.documentElement.style.setProperty(
        "--color-background",
        hexToRgb(this.secondaryColor).join(" ")
      );
      document.documentElement.style.setProperty(
        "--color-primary-light",
        hexToRgb(this.secondaryColor).join(" ")
      );
      document.documentElement.style.setProperty(
        "--color-primary-dark",
        hexToRgb(this.darkColor).join(" ")
      );
*/

      return true;
    },
    /**
     * Recuperes les societes
     * @returns
     */
    async fetchUnites(): Promise<boolean> {
      const { status, data } = await societesService.getUnites();
      if (!isValidStatus(status) && data.unites) {
        this.unites = [];
        this.error = Error("La récupération des unités a échoué.");
        return false;
      }
      this.unites = data.unites;
      return true;
    },

    clearPrefs() {
      this.active = false;
      this.nom = "";
      this.stockage = false;
      this.limitStockage = 0;
      this.slogan = "";
      this.image = null;
      this.logo = null;
      this.logoModeClair = null;
      this.logoModeSombre = null;
      this.titreInstruction = "";
      this.color = "#FFFFFF";
      this.instruction = "";
      this.desactive = "";
      this.majInfoEntreprise = false;
    },
  },
});
