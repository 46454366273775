import { reactive, ref, watch } from "vue";
import { IFrais, INoteFrais } from "@/pages/bureau/@models/frais.model";
import { rgbToHex } from "@/utils/colors";

class DefaultClass {
  public societe: IPrefsSociete = reactive({
    logo: "",
    couleurPrincipale: rgbToHex(0, 140, 255),
    couleurSecondaire: rgbToHex(240, 246, 252),
    nomSociete: "",
  });
  public login: IPrefsLogin = reactive({
    logo: "",
    slogan: "",
    couleurPrincipale: rgbToHex(0, 140, 255),
    couleurSecondaire: rgbToHex(227, 227, 227),
  });

  constructor() {
    //
  }
}
const PrefsTemp = new DefaultClass();

export default reactive(PrefsTemp);

export interface IPrefsSociete {
  logo: string;
  couleurPrincipale: string;
  couleurSecondaire: string;
  nomSociete?: string;
}

export interface IPrefsLogin extends IPrefsSociete {
    slogan: string;
}