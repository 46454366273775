export function formatPhoneInternational(prefix = "", phone = "") {
  if (!phone) return phone;
  //remove all spaces
  phone = phone.replace(/\s/g, "");
  //remove 0 if it's the first character
  if (prefix && phone[0] == "0") {
    phone = phone.substring(1);
  }
  //create an array of phone split by 2 if from France or 3 if from other country
  let phoneArray = [];
  if (prefix == "+33" || !prefix) {
    if (prefix) {
      phoneArray = [
        phone.substring(0, 1),
        ...phone.substring(1).match(/.{1,2}/g),
      ];
    } else {
      phoneArray = phone.match(/.{1,2}/g);
    }
  } else {
    phoneArray = phone.match(/.{1,3}/g);
  }
  //join the array with space
  return [prefix, ...phoneArray].filter(Boolean).join(" ");
}

export function callNumber(prefix = "", phone = "") {
  if (!phone) return phone;
  //remove all spaces
  phone = phone.replace(/\s/g, "");
  //remove 0 if it's the first character
  if (prefix && phone[0] == "0") {
    phone = phone.substring(1);
  }
  //call the number in the same tab
  window.location.href = "tel:" + prefix + phone;
}

export function mailTo(email = "") {
  window.open("mailto:" + email);
}
