export function isMobile(): boolean {
  return (
    /Mobi|Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ) || isSafariMobile()
  );
}

export function isMobileWidth(value = 540): boolean {
  /*
        sm: "540px",
        md: "720px",
        lg: "960px",
        xl: "1140px",
        */
  return window.innerWidth < value;
}

export function isSafari(): boolean {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isSafariMobile(): boolean {
  return navigator.maxTouchPoints > 0 && isSafari();
}
