import { IResponse } from "@/@types/api";
import { isValidStatus } from "@/utils/http";
import { useAuthStore } from "@/stores/auth";
import axios from "axios";
import { Platform } from "@/res/axiosConfig";
const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV == "production"
      ? `${window.location.href.split("/web/")[0]}/API/V3/`
      : process.env.VUE_APP_BIMOFFICE_API_URL,
  headers: {
    "x-api-key": process.env.VUE_APP_BOFFICE_API as string,
  },
});

// Config global des Headers
axiosInstance.interceptors.request.use(
  async (config) => {
    if (!config.headers) {
      config.headers.set("x-api-key", process.env.VUE_APP_BOFFICE_API);
      config.headers.set("Authorization", "");
      config.headers.set("IdentifiantMachine", "");
    }

    // Authentification
    const authStore = useAuthStore();
    if (authStore.token) {
      config.headers.set("Authorization", authStore.token);
      config.headers.set("IdentifiantMachine", "1");
    }
    if (window.location.href.includes("ao#")) {
      config.headers.set("Platform", Platform.AO);
    } else if (window.location.href.includes("viewer#")) {
      config.headers.set("Platform", Platform.VIEWER_IFC);
    } else {
      config.headers.set("Platform", "UNKNOWN");
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Config global des réponses
axiosInstance.interceptors.response.use(
  (response) => {
    const authStore = useAuthStore();
    authStore.setCurrentRequestError(0);
    const res = response.data as IResponse;
    // Gestion des requetes invalides
    if (!isValidStatus(response.status)) {
      return Promise.reject(new Error(res.message || "Error"));
    }
    // Gestion des requete valides
    else {
      return res as any;
    }
  },
  (error) => {
    const authStore = useAuthStore();
    // Si erreur d'authentifaction, on reset le token et on redirige vers la page Login

    if (window.location.href.includes("ao#")) {
      switch (error?.response?.status) {
        case 600:
          {
            authStore.setCurrentRequestError(600);
          }
          break;
        case 401:
          {
            authStore.setCurrentRequestError(401);
            const codeErreur = error?.response?.data?.error?.code;

            if ([2, 3].includes(codeErreur)) {
              //2: Token invalide, 3: Token expiré
              const authStore = useAuthStore();
              authStore.logout();
              //refresh
              window.location.reload();
            }
          }
          break;
        default:
          {
            authStore.setCurrentRequestError(0);
          }
          break;
      }

      //return Promise.reject(error)
      return error;
    }
  }
);

export default axiosInstance;
