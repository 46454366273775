export const isPreviewInUrl = () => {
  if (window.location.href.includes("#/preview")) {
    return true;
  }
  return false;
};

export const isPreviewLogin = () => {
  if (window.location.href.includes("#/preview_login")) {
    return true;
  }
  return false;
};


export function urlHasParameter(parameter: string) {
  const urlParams = getUrlParams();
  let hasParameter = false;
  urlParams.forEach((param) => {
    if (param[0] === parameter) {
      hasParameter = true;
    }
  });
  return hasParameter;
}

export function getUrlParams() {
  const params = [];
  const url = window.location.href.toString();
  const _urlHasParams = url.includes("?");
  if (_urlHasParams) {
    const _params = url.split("?")[1].split("&");
    _params.forEach((param) => {
      params.push(param.split("="));
    });
  }

  return params as [string, string][]; // [ [key, value], [key, value] ]
}

export function openUrlInNewTab(url: string) {
  if (url.includes("http")) {
    window.open(url, "_blank");
  } else {
    window.open("http://" + url, "_blank");
  }
}